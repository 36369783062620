import React, { Fragment } from 'react';
import Header from '../../components/header';
import Breadcrumbs from '../../components/breadcrumbs'
import Faqs from '../../components/faq'
import PartnerSlider from '../../components/partner'
import Gettouch from '../../components/gettous'
import Jobs from '../../components/job'
import FooterT from '../../components/footerTop';
import FooterBottom from '../../components/footerbottom';

const Career = () => {
    return (
        <Fragment>
            <Header />
            <Breadcrumbs pagename={'Career'} CrumbsTitle={'Join us. Revolutionize FinTech.'} />
            <Jobs />
            <FooterT />
            <FooterBottom />
        </Fragment>
    )
};
export default Career;
