import React from 'react';
import { HashRouter, Switch } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Routes from "../__Routes";
import '../../css/style.css';
import '../../css/responsive.css';
import ScrollToTop from './ScrollToTop'

function App() {
  return (
    <HashRouter basename="/">
      <ScrollToTop />
      <div className="App">
        <Switch>
          <Routes />
        </Switch>
        <ToastContainer />
      </div>
    </HashRouter>
  );
}

export default App;
