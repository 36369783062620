import React from 'react';
import { Switch, Route } from 'react-router-dom';

// Route Setting
import HomePage from "../HomePage";
import HomePage2 from "../HomePage2";
import HomePage3 from "../HomePage3";
import HomePage4 from "../HomePage4";
import Aboutpage from "../AboutPage";
import ServicePage from "../ServicePage";
import TeamPage from "../TeamPage";
import ServiceDetails from "../ServiceDetails";
import BlogPage from "../BlogPage";
import BlogDetailsPage from "../BlogDetailsPage";
import Faq from "../Faqpage";
import Privacy from '../PrivacyPage';
import ShopPage from "../shoppage";
import ShopDetailsPage from "../shopdetailspage";
import CheckOut from "../Checkoutpage";
import CartPage from "../CartPage";
import ErrorPage from "../404page";
import ReadyTravel from '../ReadyTravel';
import SignIn from "../Signin";
import SignUp from "../RagisterPage";
import ContactPage from "../ContactPage";
import ComeingSoon from "../ComeingSoon";
import Career from "../Career";
import ReadyFinSec from "../ReadyFinSec"
import PrivacyPolicy from "../PrivacyPolicy"
// Normal Route
import PrivateRoute from "../_PrivateRoute";
import PublicRoute from "../_PublicRoute";

export default function Routes() {
    return (
        <Switch>
            <PublicRoute
                exact
                path="/"
                component={HomePage}
            />
            <PrivateRoute
                exact
                path="/readytravel"
                component={ReadyTravel}
            />
            <PrivateRoute
                exact
                path="/privacypolicy"
                component={PrivacyPolicy}
            />
            <PrivateRoute
                exact
                path="/readyfinsec"
                component={ReadyFinSec}
            />
            <PrivateRoute
                exact
                path="/careers"
                component={Career}
            />
            <PrivateRoute
                exact
                path="/privacy"
                component={Privacy}
            />
            <PrivateRoute
                exact
                path="/about"
                component={Aboutpage}
            />
            <PrivateRoute
                exact
                path="/team"
                component={TeamPage}
            />
            <PrivateRoute
                exact
                path="/faq"
                component={Faq}
            />
            <PrivateRoute
                exact
                path="/404"
                component={ErrorPage}
            />
            <PrivateRoute
                exact
                path="/contact"
                component={ContactPage}
            />
            {/* <Route path="/login" component={LoginPage} />
            <Route path="/signup" component={SignupPage} /> */}
            <Route exact component={HomePage} />
        </Switch>
    );
}
