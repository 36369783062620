import React from 'react'
import { Link } from 'react-router-dom'
import App1 from '../../images/btn1.png'
import App2 from '../../images/btn2.png'
import Logo from '../../images/pecutus.jpg'

import './style.css'

const FooterT = () => {
  return (
    <div className="top-footer no-bg">
      <div className="container">
        <div className="footer-content">
          <div className="row">
            <div className="col-lg-3 col-md-6 col-sm-6">
              <div className="widget widget-logo">
                <h1 style={{ color: "#006a6a" }}>Pecutus</h1>
                <p>Empowering Travellers through FinTech.</p>
              </div>
            </div>
            <div className="col-lg-2 col-md-6 col-sm-6">
              <div className="widget widget-links">
                <h3 className="widget-title">QUICK LINKS</h3>
                <ul className="lnks-list">
                  <li><a href="https://pecutus.atlassian.net/servicedesk/customer/portals" title="">Customer Support</a></li>
                  <li><Link to="/faq" title="">FAQ</Link></li>
                  <li><Link to="/contact" title="">Contact Us</Link></li>
                  <li><Link to="/careers" title="">Careers</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-4 col-sm-6">
              <div className="widget widget-contact">
                <h3 className="widget-title">Contact Us</h3>
                <ul className="lnks-list">
                  <li>9/F 19W Hong Kong Science and Technology Park, Hong Kong</li>
                  <li>+852 8193 0353</li>
                  <li><Link to="/" title="">www.pecutus.com</Link></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-2 col-md-3 col-sm-6">
              <div className="widget widget-about">
                <h3 className="widget-title">ABOUT</h3>
                <ul className="lnks-list">
                  <li><Link to="/" title="">Home</Link></li>
                  <li><Link to="/readytravel" title="">readytravel</Link></li>
                  <li><Link to="/team" title="">Team</Link></li>
                  <li><a href="https://medium.com/goreadytravel">News</a></li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3 col-md-5">
              <div className="widget widget-app">
                <h3 className="widget-title">Download readytravel</h3>
                <ul className="download-btns">
                  <li><a href="https://apps.apple.com/hk/app/readytravel/id1470585976"><img src={App1} alt="l-img" class="responsive" /></a></li>
                  <li><a href="https://play.google.com/store/apps/details?id=com.pecutus.ReadyTravel"><img src={App2} alt="l-img" class="responsive" /></a></li>
                </ul>
                <h3>Connect with us</h3>
                <ul className="social-links">
                  <li><a href="https://www.instagram.com/goreadytravel/"><i className="fa fa-facebook"></i></a></li>
                  <li><a href="https://twitter.com/goreadytravel"><i className="fa fa-twitter"></i></a></li>
                  <li><a href="https://www.youtube.com/channel/UC56jnU8lQDkD6Qvv3mhD7KA"><i className="fa fa-youtube"></i></a></li>
                  <li><a href="https://www.linkedin.com/company/pecutus"><i className="fa fa-linkedin"></i></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default FooterT;
