import React, { Fragment } from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={{ padding: 30 }}>
      <p>
        We will use and protect your Personal Data in compliance with the Personal Data (Privacy) Ordinance, Cap. 486 ("the Ordinance"), guidelines issued by PCPD (the Privacy Commissioner for Personal Data), following the Six Data Protection Principles.
      </p>
      <h4>
        Personal Data
      </h4>
      <p>
        In this Notice, "Personal Data" means any data which the Company has, or may have access to, upon which can be used to identify a person, whether or not the relevant data is correct. The data are kept secure and only made accessible by Pecutus.
      </p>
      <p><b>
        When you visit, register or use services from the Website, App or kiosks, we may collect and process the following personal information:
      </b></p>
      <p>
        Information that you provide by filling in forms the App at the time of registering to use the App and kiosk, subscribing to our service, posting material from any social network or online account or requesting further services. This includes name, telephone number, email address, residential address, date of birth, nationality, identification details (e.g. passport numbers, ID numbers, photocopies of personal identification documents) and details of proposed currency swaps, and fulfilment requests. We may also ask you for information when you enter offline activities sponsored or hosted by us, and when you report a problem with us.
      </p>
      <p>
        We may keep a record of that correspondence when you contact us for an enquiry or complain.
      </p>
      <p>
        We may also ask you to complete surveys that we use for research purposes, although you do not have to respond to them.
      </p>
      <p>
        Details of your account and activities with us, through our App, kiosks or other fulfilment methods. Details of your visits to our App and kiosk –information about your computer, mobile device or other item of hardware through which you access and use the Website (including without limitation your IP address, geographical location, browser/platform type and version, internet service provider, operating system, referral source/exit pages, length of visit/usage, page views and any search terms you use).
      </p>
      <p><b>
        Collecting Personal Data
      </b></p>
      <p>
        In order to process, administer and/ or manage your relationship and/ or account with us, it may be necessary for us to collect, use, disclose and/ or process your Personal Data or other personal information. These may include those listed in our products or services application form(s), which may have been submitted via e-mail, website, mobile application or any other method.
      </p>
      <p>
        Purposes for the Collection, Use, and Disclosure of Your Personal Data We will use your personal data for the following purposes:
      </p>
      <p>
        Identification and verification: verification of your identity; handling requests of access, correction, enquiries, erasure of personal data and complaints in relation to the Website;
      </p>
      <p>
        Provision of Services: operating, maintaining and providing our Website services; confirming instruction from you; communication between you and the Company in respect of the App and kiosk services by Pecutus to you;
      </p>
      <p>
        User Account and Activity Handling: registration as an User; selecting and presenting services, information, privileges and offers that matches your needs or to create personalised contents etc;
      </p>
      <p>
        Notification: notifying you of changes that Pecutus has made or plans to make to the Website, the App or the kiosks;
      </p>
      <p>
        Compliance: assisting our obligations on anti-money laundering and counter-terrorist financing;
      </p>
      <p>
        Improvement of the services: requesting your feedback or comments regarding the Website, the App or the kiosks;
      </p>
      <p>
        Protecting the Company: protecting and defending our Company's rights, properties, employees, directors, shareholders, consultants and customers;
      </p>
      <p>
        Direct Promotion and Marketing: Only after obtaining your consent, can the Company use your personal data for marketing or contact you from time to time to share promotional or marketing information in respect of the service of the Company;
      </p>
      <p>
        Statistics: carry out statistics and data analysis, measure the effectiveness of advertisements and web searches and consumer's preference, such analysis will contain only anonymous data for our reference as well as improving our service quality and the quality service; and Security: for fraud and hacking prevention so as to impose related restrictions and account bans to violators, who might be involved in fraudulent and unlawful activities.
      </p>
      <p><b>
        Withdrawal of Consent
      </b></p>
      <p>
        You may communicate your objection to our use and/or disclosure of your Personal Information for any of the Purposes as stated in this Notice at any time by contacting our Data Protection Officer through hello@pecutus.com , email subject: Personal Data Consent Withdrawal. However, please take note that depending on the nature and specifics of your objection, we may not be able to continue to provide our products or services to you or perform on any contract we have with you.
      </p>
      <p><b>
        Disclosure of Personal Data
      </b></p>
      <p>
        We will take reasonable steps to protect your Personal Data against unauthorised disclosure. To the extent permitted by law, we may transfer or disclose your Personal Data for the Purposes stated in this Notice, to the following entities or parties:
      </p>
      <p>
        Pecutus Technologies Limited's related companies;
      </p>
      <p>
        Agents, contractors or third party service providers who provide operational services to You
      </p>
      <p>
        Technologies, such as courier services, telecommunications, information technology, payment, payroll, processing, training, market research, storage, archival, customer support investigation services or other services to You Technologies;
      </p>
      <p>
        Vendors or other third party service providers, including but not limited to advertisers and event management companies, in connection with promotions and services offered by You Technologies;
      </p>
      <p>
        Professional advisors and external auditors, including legal advisors, financial advisors and consultants;
      </p>
      <p>
        Co-branded and other business partners; and/or Any other party to whom you authorise us to disclose your Personal Data to.
      </p>
      <p><b>
        Retention of Personal Data
      </b></p>
      <p>
        We will retain all your personal data for so long as is necessary for the fulfilment of the purpose(s) for which they were collected and of the legal requirements, for at least 5 years.
      </p>
      <p>
        If you desire to erase your personal data from our electronic databases after ceasing to use our services, please contact hello@pecutus.com , email subject: Personal Data Removal.
      </p>
      <p>
        Our information and data are stored and maintained in our electronic database, facilities, servers and back-up servers which may be located outside your country, where the legal protection and standard of security requirements may not be equivalent to the same provided by and legally required by the applicable laws and regulations of your country.
      </p>
      <p><b>
        Accuracy and Access of your Personal Data
      </b></p>
      <p>
        In order to ensure the correctness of your personal data, you are responsible to provide accurate and relevant data in order for our services and compliance to be executed. You may receive our request of confirmation or verification. Please feel free to contact us for correction or change of personal data or opt-out from receiving future direct promotional and marketing information.
      </p>
      <p>
        You have a right to request access to, and to request correction of, your personal data in relation to your application. If you wish to exercise these rights, please contact hello@pecutus.com , email subject: Personal Data Enquiry. In the event that you wish to access or amend your Personal Data, the Company may request you to provide personal details in order to verify and confirm your identity. Your HKID card number or passport number cannot be amended unless such data is proved to be inaccurate.
      </p>
      <p><b>
        Acknowledgement and Consent
      </b></p>
      <p>
        By providing your personal information to us, or using our website, or signing up for or accessing products or services we offer during any of our interactions, either directly with us or through our agents and/ or partners, you are consenting to the following:
      </p>
      <p>
        The Company may collect, use, disclose and/or process your Personal Data for the Purposes as described above;
      </p>
      <p>
        The Company may collect Personal Data about you from sources other than yourself and use, disclose and/ or process the same for one or more of the Purposes as described above; and
      </p>
      <p>
        The Company may disclose your Personal Data to our third party service providers or agents (including its lawyers / law firms) for the Purposes as described above.
      </p>
      <p><b>
        Research
      </b></p>
      <p>
        For research or statistical purposes, we may use or disclose to others anonymised or aggregated data.
      </p>
      <p><b>
        Use of Cookies
      </b></p>
      <p>
        Cookies are small files that are automatically stored on your web browser in your computer that can be retrieved by the Website. Cookies may be used to remember you and your preferences when you visit the Website and enable us to tailor the Website to your needs. The information collected by Cookies is anonymous visitor's personalized settings information and contains no name or address information or any information that will enable anyone to contact you via telephone, email or any other means. We will not collect any personal data from you when the cookies are being used. We or third-party vendors engaged by us may use cookies to inform and optimize the Website and to provide marketing materials to you based on your past activities. We generally use such automatically collected information and data to estimate the audience size of the Website, to gauge the popularity of various parts of the Website, to track the entries into promotional activities and special events, to measure traffic patterns and to otherwise perform administrative functions. You can disable cookies by changing the settings of your web browser. Cookies cannot be used to run programs or deliver viruses to your computer and/or device.
      </p>
    </div>
  )
};
export default PrivacyPolicy;