import React from 'react'
import { Link } from 'react-router-dom'
import icon3 from '../../images/Ficon/icon-9.png'
import icon4 from '../../images/Ficon/icon-10.png'
import icon5 from '../../images/Ficon/icon-11.png'

import './style.css'

const Service = () => {
    return (
        <section className="services_sec" id="learn">
            <div className="container">
                <div className="sec-title2">
                    <h4>What is ReadyTravel?</h4>
                    <h2 style={{ color: '#55cda5' }}>An Award Winning<br />Peer to Peer FX Platform</h2>
                </div>
                <div className="services_row">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="service-col">
                                <span><img src={icon5} alt="sev" /></span>
                                <h3><Link to="/service-details" title="">Bank-grade Security</Link></h3>
                                <p>ReadyTravel works with institutional partners to integrate with the HK banking system, whilst providing the highest levels of security.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="service-col">
                                <span><img src={icon3} alt="sev" /></span>
                                <h3><Link to="/service-details" title="">Open API</Link></h3>
                                <p>Our Plug and Play APIs make integration with ReadyTravel feel like a piece of cake, for large banks and small businesses alike.</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6 col-12">
                            <div className="service-col">
                                <span><img src={icon4} alt="sev" /></span>
                                <h3><Link to="/service-details" title="">Physical & Digital Money</Link></h3>
                                <p>Through our multi-currency kiosks, we can process up to 80 different currencies, in addition to electronic transfers, ensuring our customer's needs are well served.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default Service;
