import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import './style.css'

const PrivacyCard = (props) => {
  return (
    <section className="page-content">
      <div className="container">
        <div className="page-content-row">
          <div className="faqs_page">
            <div className="row">
              <div className="col-lg-5">
                <div className="faqs-text">
                  <h4>ReadyTravel</h4>
                  <h3>Privacy</h3>
                </div>
              </div>
              <div className="col-lg-7" style={{ marginTop: '10px' }}>
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Why is cash still needed?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        Despite the prevalence of digital payment, the use of cash still makes sense for traveling to an unfamiliar and foreign destination. Cash is universally accepted and Europeans still love paying cash as according to the ECB Study 2017. In Asian countries like Japan, Thailand, Singapore, Korea, Taiwan and Philippines, most street vendors still only take cash, and some small stores don't take cards at all or may give you a discount if you pay in cash. As such, the safest way to travel overseas is perhaps a combination of both cash and digital.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Why are your rates so good?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        With ReadyTravel - a new way to manage travellers' currency needs, we simply help to swap your currency with other travellers who want yours through bidding. So we can bring you the best rates with thin margin at a "friend's rate". Since we leverage unmanned technology to automate the exchange process, we are able to pass on the cost savings to you. There are no traditional middlemen and no hidden fees, and so you get cheaper rates and more to spend.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        What currencies do you accept?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>ReadyTravel is able to accept up to 80 currencies in different denominations. But in phase one, ReadyTravel is configured to accept the following 14 currencies namely AUD CAD CNY EUR GBP HKD IDR JPY KRW MYR SGD THB TWD and USD, and dispense 6 currencies namely JPY USD EUR CNY AUD and GBP.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Are there any maximum transaction amount?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        During our trial, customers are restricted to exchanging no more than 15,000 HKD or equivalent per day. There are no limits on withdrawals however. For high value transactions we may request some extra information from you in order to comply with the KYC and AML regulatory requirements.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Do you accept both banknotes and coins?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        We support banknotes in multi-currencies in the first phase of launch but plan to support coins in the future.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Where do I recycle or collect the cash?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        Please check out the list of the Ready Kiosk locations in our mobile app. You may recycle or collect the cash in respective currency similar to an ATM.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        How secure is my transaction?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        If you return home with leftover currency, we provide you with a safe way to your money into our platform. This alleviate your trouble of placing your leftover currency idle at home. If you are on an outbound trip, making the transaction is no different than paying in advance for buying goods in an online store.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Why do you require my identification details?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        We need to identify and verify all customers to ensure the KYC requirements and that all orders are legitimate. Most importantly, we want to ensure all your transactions are secure.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        What are your opening hours?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        ReadyTravel is open for 24x7 service round the clock. Based on the unmanned platform, users can access the money transaction service at their own pace with privacy. This is the convenience that we strive to bring to our customers.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        Where is your headquarter office?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>
                        We are an Incu-Tech company of HKSTP in Hong Kong Science Park, located at Unit 973, Building 19W, Hong Kong Science Park, Shatin, N.T., Hong Kong.
                      </Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default PrivacyCard;
