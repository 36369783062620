
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import hkstp from '../../images/resources/hkstp.png';
import incutech from '../../images/resources/incutech.png';
import hkstpLeap from '../../images/resources/hkstp-leap.png';
import block71 from '../../images/resources/block71.png';
import hkstpGAA from '../../images/resources/hkstp_gaa.png';
import fintechBusinessCamp from '../../images/resources/fintechBusinessCamp.png';
import faaward from '../../images/resources/FAaward.jpg';
import certOfMerit from '../../images/resources/CertOfMerit.png'

import './style.css'

class PartnerSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 2,
      autoplay: true,
      margin: 10,
      loop: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="partner-sec">
        <div className="container">
          <div className="partner-sec-details">
            <div className="pt-carousel">
              <Slider {...settings}>
                <div className="partner-col">
                  <img src={hkstp} alt="" />
                </div>
                <div className="partner-col">
                  <img src={incutech} alt="" />
                </div>
                <div className="partner-col">
                  <img src={hkstpLeap} alt="" />
                </div>
                <div className="partner-col">
                  <img src={hkstpGAA} alt="" />
                </div>
                <div className="partner-col">
                  <img src={block71} alt="" />
                </div>
                <div className="partner-col">
                  <img src={fintechBusinessCamp} alt="" />
                </div>
                <div className="partner-col">
                  <img src={faaward} alt="" />
                </div>
                <div className="partner-col">
                  <img src={certOfMerit} alt="" />
                </div>
              </Slider>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerSlider;




