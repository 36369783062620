import React, { Fragment } from 'react';
import Header from '../../components/header';
import AllInOne from '../../components/allinone'
import FooterT from '../../components/footerTop';
import FooterBottom from '../../components/footerbottom';
import SmartSystem from '../../components/smartsystem';
import SmartSystem2 from '../../components/smartsystem2';
import SmartSystem3 from '../../components/smartsystem3';
import SmartSystem4 from '../../components/smartsystem4';

const SignUp = () => {
    return (
        <Fragment>
            <Header />
            <SmartSystem />
            <AllInOne />
            <SmartSystem2 />
            <SmartSystem3 />
            <SmartSystem4 />
            <FooterT />
            <FooterBottom />
        </Fragment>
    )
};
export default SignUp;