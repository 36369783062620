import React from 'react'


const SectionTite2 = ( props ) => {
    return(
        <div className="sec-title center-style">
            <h2>{props.sectitle2}</h2>
            <div className="clearfix"></div>
        </div>
    )
}
export default  SectionTite2;