
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import climg1 from '../../images/resources/client-img.png'
import climg2 from '../../images/resources/client-img.png'

import './style.css'

class ClientSay extends Component {

    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: false,
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: false,
            margin: 10,
            loop: true,
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true
                    }
                },
                {
                    breakpoint: 991,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 576,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
                // You can unslick at a given breakpoint now by adding:
                // settings: "unslick"
                // instead of a settings object
            ]
        };
        return (
            <section className="client-sec">
                <div className="container">
                    <div className="sec-title2 text-center">
                        <h4>Testimonials</h4>
                        <h2 style={{ color: '#55cda5' }}>What do our customers think?</h2>
                    </div>
                    <div className="client-say-sec">
                        <div className="cl-slide-sec">
                            <Slider {...settings}>
                                <div className="cl-slide">
                                    <div className="client-col">
                                        <div className="client-row">
                                            <div className="client-img">
                                                <img src={climg1} alt="alient" />
                                            </div>
                                            <div className="client-info">
                                                <h3>Smart Airways with<br />Cathay Pacific</h3>
                                                <span>Global Acceleration Academy</span>
                                            </div>
                                        </div>
                                        <p>"We are excited and impressed with the Pecutus team. The passion, enthusiasm and professionalism of Pecutus team made our colleagues have a wonderful experience in collaborating and co-creating a fit-for-purpose solution."</p>
                                    </div>
                                </div>
                                <div className="cl-slide">
                                    <div className="client-col">
                                        <div className="client-row">
                                            <div className="client-img">
                                                <img src={climg2} alt="alient" />
                                            </div>
                                            <div className="client-info">
                                                <h3>Henry</h3>
                                                <span>ReadyTravel User</span>
                                            </div>
                                        </div>
                                        <p>“I really like the kiosk, I have used it a few times. If we can have a multi-currency debit card that is connected to an e-wallet that would be great too."</p>
                                    </div>
                                </div>
                                <div className="cl-slide">
                                    <div className="client-col">
                                        <div className="client-row">
                                            <div className="client-img">
                                                <img src={climg1} alt="alient" />
                                            </div>
                                            <div className="client-info">
                                                <h3>James</h3>
                                                <span>ReadyTravel User</span>
                                            </div>
                                        </div>
                                        <p>“This is great. I really like the service and I have used it a couple times already. If I have to suggest an improvement, I’d like the company to deposit my allowance into ReadyTravel than as salary.”</p>
                                    </div>
                                </div>
                                <div className="cl-slide">
                                    <div className="client-col">
                                        <div className="client-row">
                                            <div className="client-img">
                                                <img src={climg2} alt="alient" />
                                            </div>
                                            <div className="client-info">
                                                <h3>Fabio</h3>
                                                <span>ReadyTravel User</span>
                                            </div>
                                        </div>
                                        <p>“[ReadyTravel] is convenient and easy to use.”</p>
                                    </div>
                                </div>
                                <div className="cl-slide">
                                    <div className="client-col">
                                        <div className="client-row">
                                            <div className="client-img">
                                                <img src={climg2} alt="alient" />
                                            </div>
                                            <div className="client-info">
                                                <h3>Helen</h3>
                                                <span>ReadyTravel User</span>
                                            </div>
                                        </div>
                                        <p>“I used the kiosk to change back leftover money from Thailand and Taiwan. It was really easy compared to going to find a money changer.”</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default ClientSay;
