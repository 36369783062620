import React from 'react';
import SectionTitle from '../../components/sectiontitle'
import tipimg from '../../images/resources/vector3.jpg'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    LineChartOutlined,
    UserOutlined
} from '@ant-design/icons';
import './style.css'

const ReshapeBanking = () => {
    return (
        <div className="tips-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="vector_bg">
                            <img src={tipimg} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="tips-col">
                            <SectionTitle sectitle={'Reshaping Banking as Part of the Travelling Experience'} />
                            <p>Banking is changing for the better; becoming more connected, more personal, and more integrated into our everyday lives. </p>
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-icon">
                                        <LineChartOutlined style={{ color: '#006a6a', fontSize: '48px' }} />
                                    </div>
                                    <div className="tp-info">
                                        <h3>Vertical Integration</h3>
                                        <p>Our Open API provides an interoperable platform to integrate Financial Services with travel industry partners.</p>
                                    </div>
                                </div>
                                <div className="tps-row">
                                    <div className="tp-icon">
                                        <UserOutlined style={{ color: '#006a6a', fontSize: '48px' }} />
                                    </div>
                                    <div className="tp-info">
                                        <h3>Self Service Technology </h3>
                                        <p>Enable customers to get what they need on-the-go, through AI-enabled digital services and unmanned kiosk technology.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};
export default ReshapeBanking;
