import React from 'react'
import { Link } from 'react-router-dom'

import './style.css'

const FooterBottom = () => {
  return (
    <div className="bottom-strip">
      <div className="container">
        <div className="copyright-text">
          <p>Copyright © 2020. All Rights Reserved By Pecutus Technologies Ltd</p>
          <ul className="ft-links">
            <li><Link to="/privacypolicy" title="">Privacy</Link></li>
            <li><Link to="/faq" title="">FAQs</Link></li>
            <li><Link to="/readytravel" title="">ReadyTravel</Link></li>
          </ul>
          <div className="clearfix"></div>
        </div>
      </div>
    </div>
  )
}

export default FooterBottom;
