import React from 'react';
import SectionTitle from '../sectiontitle'
import tipimg2 from '../../images/resources/vector2.jpg'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    GlobalOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import './style.css'

const FutureBanks = () => {
    return (
        <div className="tips-business-sec">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="tips-col">
                            <SectionTitle sectitle={'Today\'s FinTechs enable Tomorrow\'s Banks'} />
                            <p>In an increasingly competitive landscape, the most successful retail banks will be those who are able to foster a sense of community <span>&#38;</span> sense of identity amongst its members.</p>
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-icon">
                                        <GlobalOutlined style={{ color: '#006a6a', fontSize: '48px' }} />
                                    </div>
                                    <div className="tp-info">
                                        <h3>AI-Assisted Customer Management Solutions</h3>
                                        <p>Your customers demand intelligent support. Assistive AI technologies will be neccessary to enable a human, personal touch to be provided at scale.</p>
                                    </div>
                                </div>
                                <div className="tps-row">
                                    <div className="tp-icon">
                                        <CheckCircleOutlined style={{ color: '#006a6a', fontSize: '48px' }} />
                                    </div>
                                    <div className="tp-info">
                                        <h3>Trusted Advisors</h3>
                                        <p>As trusted advisors, banks are well-positioned to connect their members to the products and services they need the most.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="vector-sd">
                            <img src={tipimg2} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
export default FutureBanks;
