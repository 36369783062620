import React from 'react';
import SectionTitle from '../sectiontitle'
import shoppingLogo from '../../images/resources/shoppingLogo.jpg'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    GlobalOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import './style.css'

const FutureBanks = () => {
    return (
        <div >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="tips-col">
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-info" style={{textAlign:'center', paddingTop:100}}>
                                        <div className="sec-title">
                                            <h1 style={{ color: '#006a6a', margin:0, fontSize:30}}>無需支付昂貴的手續費</h1>
                                            <h1 style={{ color: '#006a6a', margin:0, fontSize:30}}>即時確認收款</h1>
                                            <div className="clearfix"></div>
                                        </div>
                                        <p style={{fontSize: 20, margin:0}}>連結至社交媒體商店 </p>
                                        <p style={{fontSize: 20, margin:0}}>即時收款或轉帳至全港銀行</p>
                                        <p style={{fontSize: 20, margin:0}}>每宗交易手續費低至港幣$0.5元*</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="subs-img" style={{marginTop:90, textAlign:'center'}}>
                            <img src={shoppingLogo} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
export default FutureBanks;
