import React from 'react'

import './style.css'

const Funfact = () => {
    return(
        <div className="facts-sec">
            <div className="container">
                <div className="sec-title">
                    <h2>Key Statistics</h2>
                </div>
                <div className="facts-row">
                    <div className="fact-col">
                        <h3>Annual Spending by<br/>Hong Kongers on<br/>Outbound Travel</h3>
                        <h2><small>$</small>26.5<span>B</span></h2>
                    </div>
                    <div className="fact-col">
                        <h3>Passengers travelling<br/>through Hong Kong<br/>each year</h3>
                        <h2>71.5<span>M</span></h2>
                    </div>
                    <div className="fact-col">
                        <h3>Annual Tourist<br/> Arrivals in APAC<br/> by 2030.</h3>
                        <h2>530<span>M</span></h2>
                      <span>+</span>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>

        </div>
    )
}

export default Funfact;
