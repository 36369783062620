import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'
import { HashLink as HLink } from 'react-router-hash-link';

const Hero2 = (props) => {

    return (
        <div className="wrapper layout3">
            <div className="banner_section">
                <div className="container">
                    <div className="banner_text">
                        <h2>Connecting People. Simplifying Travel.</h2>
                        <p>We provide travel money management solutions for the travel industry. readytravel is in private beta with a select group of partners in Hong Kong.</p>
                        <HLink smooth to="/readytravel#learn" title="" className="btn-default">Learn More</HLink>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>

    )
}

export default Hero2;
