import React, { Component } from 'react'
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom'
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Home',
    link: '/home',
  },
  {
    id: 2,
    title: 'Readytravel',
    link: '/readytravel',
  },
  {
    id: 3,
    title: 'Team',
    link: '/team',
  },
  {
    id: 4,
    title: 'Careers',
    link: '/careers',
  },
  {
    id: 5,
    title: 'FAQs',
    link: '/faq'
  },
  {
    id: 6,
    title: 'Contact',
    link: '/contact'
  },
]

export default class MobileMenu extends Component {

  state = {
    isMenuShow: false,
    isOpen: 0,
  }

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow
    })
  }

  setIsOpen = id => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id
    })
  }

  render() {

    const { isMenuShow, isOpen } = this.state;

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
          {/* <div className="clox" onClick={this.menuHandler}>Close Me</div> */}

          <ul className="responsivemenu">
          <><li><a href="https://fin.pecutus.com/en">Fin - Simple Business Accounts</a></li></>
            {menus.map(item => {
              return (
                <li key={item.id}>
                  {item.submenu ? <p onClick={this.setIsOpen(item.id)}>
                    {item.title}
                    {item.submenu ? <i className="fa fa-angle-right" aria-hidden="true"></i> : ''}
                  </p> : <><Link to={item.link}>{item.title}</Link></>}
                  {item.submenu ?
                    <Collapse isOpen={item.id === isOpen}>
                      <Card>
                        <ul>Test</ul>
                        <CardBody>
                          <ul>
                            {item.submenu.map(submenu => (
                              <li key={submenu.id}><Link className="active" to={submenu.link}>{submenu.title}</Link></li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                    : ''}
                </li>
              )
            })}
          </ul>

        </div>

        <div className="showmenu" onClick={this.menuHandler}><i className="fa fa-bars" aria-hidden="true"></i></div>
      </div>
    )
  }
}
