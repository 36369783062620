import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import './style.css'

const Jobs = (props) => {
    return (
        <section className="page-content">
            <div className="container">
                <div className="page-content-row">
                    <div className="faqs_page">
                        <div className="row">
                            <div className="col-lg-5">
                                <div className="faqs-text">
                                    <h4>Working for Pecutus</h4>
                                    <h3>Do you want to help build the future of Digital Finance?</h3>
                                    <p>Our current job openings are listed here. To apply for a position, please send your CV and Covering Letter to &#116;&#097;&#108;&#101;&#110;&#116;&#064;&#112;&#101;&#099;&#117;&#116;&#117;&#115;&#046;&#099;&#111;&#109;.</p>
                                    <Link to="/contact" title="" className="btn-style2">Get More Help</Link>
                                </div>
                            </div>
                            <div className="col-lg-7">
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Card.Header>
                                            <Accordion.Toggle as={Button} variant="link" eventKey="0">
                                                AI Research and Development (Post-doctoral)
                                        </Accordion.Toggle>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>We are looking for a highly motivated and independent individual to join our growing team to undertake research and development on the theme of ‘The Future of Banking’.
                                        <br /><br />
                                        The successful candidate will leverage their in-depth knowledge in their field toward the development and refinement of technology-driven solutions in various areas of digital finance and banking technology, with specific emphasis on modern retail FX solutions. Your day-to-day work could be anything from statistical analysis, training machine learning models, to developing prototypes and MVPs.
                                        <br /><br />
                                        You will work closely with our Chief Technology Officer and a small team of data scientists and software developers in a highly collaborative, cross-functional and supportive environment. You will be given freedom to explore new technologies, guidance to learn new skills, and be supported to add value in different areas of the business.
                                        <br /><br />
                                                <a target="_blank" href="https://www.linkedin.com/jobs/view/2011761774/">Click Here to Learn More</a></Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                </Accordion>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    );
}

export default Jobs;
