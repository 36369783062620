import React from 'react';
import SectionTitle from '../sectiontitle'
import oldppl from '../../images/resources/oldppl.jpeg'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    GlobalOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import './style.css'

const FutureBanks = () => {
    return (
        <div >
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="vector-sd" style={{padding:20, textAlign:'center'}} >
                            <img src={oldppl} alt="" />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="tips-col">
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-info" style={{textAlign:'center', paddingTop:100}}>
                                        <div className="sec-title">
                                            <h1 style={{ color: '#006a6a', fontSize:30}}>一鍵查看財務報告</h1>
                                            <div className="clearfix"></div>
                                        </div>
                                        <p style={{fontSize: 20, margin:0}}>輕鬆查看業務狀況</p>
                                        <p style={{fontSize: 20, margin:0}}>詳細明確的報表</p>
                                        <p style={{fontSize: 20, margin:0}}>客製化數據報告</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
export default FutureBanks;
