
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import kiosk from '../../images/kiosk.png'
import login from '../../images/s1.png'
import wallet from '../../images/s3.png'
import swap from '../../images/s2.png'
import confirm from '../../images/s4.png'


import './style.css'

class VisualSlider extends Component {
  render() {
    var settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      autoplay: false,
      margin: 10000,
      loop: true,
      responsive: [
        {
          breakpoint: 1200,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            autoplay: true,
          }
        },
        {
          breakpoint: 991,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
          }
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            autoplay: true,
          }
        },
        {
          breakpoint: 576,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
          }
        }
        // You can unslick at a given breakpoint now by adding:
        // settings: "unslick"
        // instead of a settings object
      ]
    };
    return (
      <section className="visual-sec">
        <div className="container">
          <div className="sec-title2 text-center">
            <h4 style={{ color: '#000000' }}>ReadyTravel Preview</h4>
            <h2 style={{ color: '#55cda5' }}>Travel Money Management,<br /> Made Simple.</h2>
            <p>ReadyTravel puts UX front and centre, ensuring a seamless FX management experience. We seek to empower travellers to see the world, discover themselves, and maximise their potential through new experiences.</p>
          </div>
          <div className="v-item">
            <Slider {...settings}>
              <div className="v-slide">
                <div className="scr-slide">
                  <img src={login} alt="" />
                </div>
              </div>
              <div className="v-slide">
                <div className="scr-slide">
                  <img src={wallet} alt="" />
                </div>
              </div>
              <div className="v-slide">
                <div className="scr-slide">
                  <img src={swap} alt="" />
                </div>
              </div>
              <div className="v-slide">
                <div className="scr-slide">
                  <img src={confirm} alt="" />
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </section>
    );
  }
}

export default VisualSlider;
