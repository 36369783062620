import React, { Component } from 'react'
import { Link } from 'react-router-dom';

import icon1 from '../../images/icon16.png'
import icon2 from '../../images/icon17.png'
import icon3 from '../../images/icon18.png'

import './style.css'

class Contact extends Component {


  state = {
    name: '',
    email: '',
    subject: '',
    events: '',
    notes: '',
    message: '',
    error: {}
  }


  changeHandler = (e) => {
    const error = this.state.error;
    error[e.target.name] = ''

    this.setState({
      [e.target.name]: e.target.value,
      error
    })
  }

  subimtHandler = (e) => {
    e.preventDefault();

    const { name,
      email,
      subject,
      events,
      message,
      notes, error } = this.state;

    if (name === '') {
      error.name = "Please enter your name";
    }
    if (email === '') {
      error.email = "Please enter your email";
    }
    if (subject === '') {
      error.subject = "Please enter your subject";
    }
    if (message === '') {
      error.message = "Please enter your message";
    }

    if (error) {
      this.setState({
        error
      })
    }
    console.log(error)
    if (error.name === '' && error.email === '' && error.email === '' && error.subject === '') {
      this.setState({
        name: '',
        email: '',
        subject: '',
        events: '',
        notes: '',
        error: {}
      })
      try {
        fetch('https://8tqvvv2mx3.execute-api.ap-east-1.amazonaws.com/latest/contactus', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json'
          },
          body: JSON.stringify({
            name: name,
            subject: subject,
            body: message,
            replyTo: email
          })
        })
      } catch (error) {
        console.log(error)
      }
    }
  }

  render() {
    const {
      name,
      email,
      subject,
      error,
      message
    } = this.state;

    return (
      <section className="page-content" id="contactus">
        <div className="container">
          <div className="page-content-row">
            <div className="contact-page">
              <div className="row">
                <div className="col-lg-6">
                  <div className="contact-col">
                    <h3>Get in touch</h3>
                    <p>If you want to know more, work with us, or simply say hello - please complete the form below and we'll get back to you really fast.</p>
                    <form onSubmit={this.subimtHandler} className="form-roww">
                      <div className="row">
                        <div className="col-lg-6 col-md-6">
                          <div className="form-field">
                            <input value={name} onChange={this.changeHandler} type="text" name="name" placeholder="Name" />
                            <p>{error.name ? error.name : ''}</p>
                          </div>
                        </div>
                        <div className="col-lg-6 col-md-6">
                          <div className="form-field">
                            <input value={subject} onChange={this.changeHandler} type="text" name="subject" placeholder="Subject" />
                            <p>{error.subject ? error.subject : ''}</p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-field">
                            <input onChange={this.changeHandler} value={email} type="email" name="email" placeholder="Email" />
                            <p>{error.email ? error.email : ''}</p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-field">
                            <textarea name="message" placeholder="Message" onChange={this.changeHandler} value={message} type="text" name="message"></textarea>
                            <p>{error.message ? error.message : ''}</p>
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-submit">
                            <button type="submit" className="btn-style2">Send Now</button>
                          </div>
                        </div>
                      </div>
                    </form>
                    <div className="our_social_links">
                      <h3>Media</h3>
                      <p>To discuss media opportunities or to obtain a copy of our press kit, please drop an email to &#104;&#101;&#108;&#108;&#111;&#064;&#112;&#101;&#099;&#117;&#116;&#117;&#115;&#046;&#099;&#111;&#109;</p>
                      <ul className="social-links">
                        <li><a href="https://www.instagram.com/goreadytravel/"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="https://twitter.com/goreadytravel"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="https://www.youtube.com/channel/UC56jnU8lQDkD6Qvv3mhD7KA"><i className="fa fa-youtube"></i></a></li>
                        <li><a href="https://www.linkedin.com/company/pecutus"><i className="fa fa-linkedin"></i></a></li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div id="map">
                    <div className="contact-map">
                      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3688.038888269317!2d114.20604915084421!3d22.427562085185286!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3404093806dd45b3%3A0x989bda2af753140c!2sPecutus%20Technologies%20Ltd!5e0!3m2!1szh-TW!2shk!4v1600229217068!5m2!1szh-TW!2shk"></iframe>
                    </div>
                  </div>
                </div>
              </div>
              <div className="contact-list-info">
                <div className="row">
                  <div className="col-lg-6 col-md-8">
                    <div className="contact_info">
                      <div className="ctt-icon clr2">
                        <img src={icon2} alt="" />
                      </div>
                      <div className="ctt-info">
                        <span>9/F 19W Hong Kong Science and Technology Park, Hong Kong</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-8">
                    <div className="contact_info">
                      <div className="ctt-icon clr3">
                        <img src={icon3} alt="" />
                      </div>
                      <div className="ctt-info">
                        <span>+852 8193 0353</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }

}
export default Contact;
