
import React, { Component } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import cmimg1 from '../../images/resources/cm-img.jpg'

import './style.css'

class TestSlider extends Component {
    render() {
        var settings = {
            dots: false,
            infinite: true,
            speed: 500,
            arrows: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            margin: 10,
            loop: true,
            fade:true
        };
        return (
            <div className="testimonial-sec">
                <div className="container">
                    <div className="testi-sec">
                        <div className="testi-wrap">
                            <Slider {...settings}>
                                <div className="testi-slide">
                                    <div className="testi-comment">
                                        <div className="cm-head">
                                            <div className="cm-img">
                                                <img src={cmimg1} alt=""/>
                                            </div>
                                            <div className="cm-info">
                                                <h3>Smart Airways with Cathay Pacific</h3>
                                                <span>Global Acceleration Academy</span>
                                            </div>
                                        </div>
                                        <p>We are excited and impressed with the Pecutus team. The passion, enthusiasm and professionalism of Pecutus team made our colleagues have a wonderful experience in collaborating and co-creating a fit-for-purpose solution.</p>
                                    </div>
                                </div>
                                <div className="testi-slide">
                                    <div className="testi-comment">
                                        <div className="cm-head">
                                            <div className="cm-img">
                                                <img src={cmimg1} alt=""/>
                                            </div>
                                            <div className="cm-info">
                                                <h3>JETCO</h3>
                                                <span>Banking Consortium</span>
                                            </div>
                                        </div>
                                        <p>We are very pleased to have ReadyTravel as one of our API Trusted Service Partners (TSP) and cooperating with JETCO. We have more than ten member banks in Hong Kong using JETCO APIX, bringing new and convenient foreign currency experience to customers.</p>
                                    </div>
                                </div>
                                <div className="testi-slide">
                                    <div className="testi-comment">
                                        <div className="cm-head">
                                            <div className="cm-img">
                                                <img src={cmimg1} alt=""/>
                                             </div>
                                            <div className="cm-info">
                                                <h3>Peter Mok</h3>
                                                <span>Head of Incubation & Acceleration Programmes of HKSTP</span>
                                            </div>
                                        </div>
                                        <p>Pecutus’ first deployment through our First@Science Park Programme is a major milestone for the start-up. ReadyTravel is among a multitude of disruptive technologies which got their start at First@Science Park.</p>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
            );
        }
    }

    export default TestSlider;
