import React from 'react'
import './style.css'
import { Link } from 'react-router-dom'

const Hero = (props) => {

    return (
        <div className="banner-section">
            <div className="container">
                <div className="banner-text">
                    <h2>Discover yourself through readytravel.</h2>
                    <p>readytravel enables the future of travel money management, empowering travellers to discover themselves, and maximise their potential through new experiences.</p>
                    <Link to="/readytravel" title="" className="btn-default">Go to readytravel</Link>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    )
}

export default Hero;
