import React from 'react'
//import { HashLink as HLink } from 'react-router-hash-link';
import ReadyFinance2 from '../../images/resources/ReadyFinance2.png'

const AllInOne = () => {
  return (
    <div style={{backgroundColor:'#006a6a', padding:'150px 0 100px'}}>
      <div className="container">
        <div className="get-text text-center">
            <h1 style={{color:'#FFFF'}}>
              ​一站式
            </h1>
            <h1 style={{color:'#FFFF'}}>
            社交媒體業務
            </h1>
            <h1 style={{color:'#FFFF'}}>
            管理系統</h1>
            <div style={{marginTop:30, marginBottom:50}}>
              <p style={{color:'#FFFF', margin:0, fontSize:20}}>讓你即時於電腦、平板、智能手機中</p>
              <p style={{color:'#FFFF', fontSize:20}}>管理你的社交媒體商店</p>
            </div>
            <img style={{
              borderRadius: "40%",
              width: 355,
              height: 200,
            }}src={ReadyFinance2} alt="" />
        </div>
      </div>
    </div>
  )
}

export default AllInOne;
