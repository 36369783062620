import React from 'react';
import { Accordion, Card, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom'

import './style.css'

const Faqs = (props) => {
  return (
    <section className="page-content">
      <div className="container">
        <div className="page-content-row">
          <div className="faqs_page">
            <div className="row">
              <div className="col-lg-5">
                <div className="faqs-text">
                  <h4>Frequenty Asked Questions</h4>
                  <h3>Still have questions?</h3>
                  <p>If you cannot find the answer to your question here, please feel free to drop us an email at <a target="_blank" href="mailto:&#104;&#101;&#108;&#108;&#111;&#064;&#112;&#101;&#099;&#117;&#116;&#117;&#115;&#046;&#099;&#111;&#109;">&#104;&#101;&#108;&#108;&#111;&#064;&#112;&#101;&#099;&#117;&#116;&#117;&#115;&#046;&#099;&#111;&#109;</a>. If you are working with one of our Travel Partners as an Authorized Tester for ReadyTravel and have any questions, please raise a <a target="_blank" href="https://pecutus.atlassian.net/servicedesk/customer/portal/2">Support Ticket</a>.</p>
                  <Link to="/contact" title="" className="btn-style2">Get More Help</Link>
                </div>
              </div>
              <div className="col-lg-7">
                <Accordion defaultActiveKey="0">
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        How can I try ReadyTravel?
                                        </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="0">
                      <Card.Body>The ReadyTravel platform is currently undergoing private testing with various partners. If you are an authorized tester for one of our partners, you can download the app from the App Store and register using your company e-mail address.<br /><br />If you are interested with partnering us, and work in the travel, hospitality or retail finance industry, please drop an email to &#104;&#101;&#108;&#108;&#111;&#064;&#112;&#101;&#099;&#117;&#116;&#117;&#115;&#046;&#099;&#111;&#109; or give us a call.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="1">
                        How are your currency rates so good?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="1">
                      <Card.Body>Unlike many banks and money changers, we do not charge enormous fees for using the ReadyTravel platform. We use our proprietary technology to match users in different locations to efficiently swap currency in a peer-to-peer fashion, which reduces our costs - savings which we pass on directly to our loyal customers!</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="2">
                        What currencies does ReadyTravel support?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="2">
                      <Card.Body>ReadyTravel is able to accept up to 80 currencies in different denominations. But in phase one, ReadyTravel is configured to accept the following 14 currencies: AUD CAD CNY EUR GBP HKD IDR JPY KRW MYR SGD THB TWD and USD, and dispense 6 currencies: JPY USD EUR CNY AUD and GBP. Banking payments (FPS and Direct Debit) currently support HKD only.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="3">
                        How secure is my transaction/money?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="3">
                      <Card.Body>We take security very seriously, and work with regulated financial institutions to provide our technology, utilising the highest standard of encryption and cybersecurity best practices.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="4">
                        Are you hiring?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="4">
                      <Card.Body>New positions will be listed on our <Link to="/careers" title="">Careers</Link> page. You may speculatively email talent@pecutus.com with your CV.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                  <Card>
                    <Card.Header>
                      <Accordion.Toggle as={Button} variant="link" eventKey="5">
                        Why do you collect personal information?
                      </Accordion.Toggle>
                    </Card.Header>
                    <Accordion.Collapse eventKey="5">
                      <Card.Body>We take your privacy very seriously. We require identification information in order to comply with various regulations. Please review our <Link to="/privacy" title="">Privacy Policy</Link>.</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

  );
}

export default Faqs;
