import React from 'react'
import { Link } from 'react-router-dom'
import VT from '../../images/resources/vt.png'
import ED from '../../images/resources/ed.png'
import cynthia from '../../images/resources/cyn.png'
import james from '../../images/resources/james.png'
import eric from '../../images/resources/Eric.png'
import ricks from '../../images/resources/ricks.png'
import keith from '../../images/resources/keith.png'
import nicole from '../../images/resources/nicole.png'
import wing from '../../images/resources/wing.png'
import gary from '../../images/resources/gary.png'
import wilson from '../../images/resources/wilson.png'
import michelle from '../../images/resources/michelle.png'


const Team2 = () => {
    return (
        <div className="team-sec">
            <div className="container">
                <div className="team-row">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={VT} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Victor Yip</h3>
                                    <span>Co-founder and CEO</span>
                                    <ul className="social-links">
                                        <li><a href="https://www.linkedin.com/in/victoryipchito/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={ED} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Edward Leung</h3>
                                    <span>Co-founder and<br />Head of Business Development</span>
                                    <ul className="social-links">
                                        <li><a href="https://www.linkedin.com/in/edwardleungzheng/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={cynthia} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Cynthia Cheung</h3>
                                    <span>Co-founder and APAC Lead</span>
                                    <ul className="social-links">
                                        <li><a href="https://www.linkedin.com/in/cynthiacheung1/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={james} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Dr James A. Butler</h3>
                                    <span>Chief Technology Officer</span>
                                    <ul className="social-links">
                                        <li><a href="https://www.linkedin.com/in/0james/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>

                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={eric} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Eric Wong</h3>
                                    <span>Lead Engineer</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/5ericwong/"><i
className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={ricks} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Dr Rithin P. Krishnan</h3>
                                    <span>Data Scientist</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/rithinpkrishnan"><i className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={keith} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Keith Kwok</h3>
                                    <span>Full Stack Developer</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/8keithkwok/"><i className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={nicole} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Nicole Pun</h3>
                                    <span>FinTech Associate</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/nicolepun/"><i
className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={wing} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Kam Wing Tsui</h3>
                                    <span>UXUI Design</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={gary} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Gary Lam</h3>
                                    <span>Full Stack Developer</span>
                                    <ul className="social-links">
                                        <li><a href="https://www.linkedin.com/in/gary-lam-16b487188/"><i className="fa fa-linkedin"></i></a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={wilson} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Wilson Lin</h3>
                                    <span>Full Stack Developer</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/wilson-chi-wing-lin-703b40172"><i className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="team-col">
                                <div className="team-img">
                                    <img src={michelle} alt="team" />
                                </div>
                                <div className="team-info">
                                    <h3>Michelle Lau</h3>
                                    <span>FinTech Associate</span>
                                    <ul className="social-links">
  <li><a href="https://www.linkedin.com/in/michelleltm/"><i className="fa fa-linkedin"></i></a></li>
 </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Team2;
