import React from 'react';
import SectionTitle from '../sectiontitle'
import GG from '../../images/resources/gg.png'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    GlobalOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import './style.css'

const FutureBanks = () => {
    return (
        <div style={{padding: '200px 0 70px'}}>
            <div className="container" >
                <div className="row">
                    <div className="col-lg-6">
                        <div className="tips-col">
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-info">
                                        <div className="sec-title">
                                            <div>
                                                <h1 style={{ color: '#006a6a',fontSize: 60}}>社交媒體商戶</h1>
                                                <h1 style={{ color: '#006a6a', fontSize: 60}}>智能管理系統</h1>
                                            </div>
                                            <div className="clearfix"></div>
                                        </div>
                                        <p style={{ fontSize: 20 , margin:0}}>提供簡潔、便捷、低成本的管理方案</p>
                                        <p style={{ fontSize: 20, margin:0}}>助你全面發展社交商務</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6 col-xs-12 col-12">
                        <div style={{ alignSelf:'center',textAlign:'center' }}>
                            <img style={{
                                    width: '80%',
                                    height: '80%',
                                    }} src={GG} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
export default FutureBanks;
