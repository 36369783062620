import React, { Fragment } from 'react';
import Header from '../../components/header';
import Breadcrumbs from '../../components/breadcrumbs'
import PrivacyCard from '../../components/privacy'
import PartnerSlider from '../../components/partner'
import Gettouch from '../../components/gettous'
import FooterT from '../../components/footerTop';
import FooterBottom from '../../components/footerbottom';

const Privacy = () => {
    return (
        <Fragment>
            <PrivacyCard />
            <FooterBottom />
        </Fragment>
    )
};
export default Privacy;