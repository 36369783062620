import React from 'react';
import SectionTitle from '../sectiontitle'
import stockimg from '../../images/resources/stock.jpeg'
import icon7 from '../../images/Ficon/7.png'
import icon8 from '../../images/Ficon/8.png'
import {
    GlobalOutlined,
    CheckCircleOutlined
} from '@ant-design/icons';
import './style.css'

const FutureBanks = () => {
    return (
        <div style={{ marginBottom:100}}>
            <div className="container">
                <div className="row">
                    <div className="col-lg-6 col-12">
                        <div className="tips-col">
                            <div className="tps-col">
                                <div className="tps-row">
                                    <div className="tp-info" style={{textAlign:'center', paddingTop:100}}>
                                        <div className="sec-title">
                                            <h1 style={{ color: '#006a6a', fontSize:30}}>無需擔心外匯升跌</h1>
                                            <div className="clearfix"></div>
                                        </div>
                                        <p style={{fontSize: 20, margin:0}}>免卻繁瑣昂貴的外匯風險管理</p>
                                        <p style={{fontSize: 20, margin:0}}>最佳匯率保證​</p>
                                        <p style={{fontSize: 20, margin:0}}>輕鬆支付海外供應商</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 col-12">
                        <div className="subs-img" style={{padding:20, textAlign:'center'}} >
                            <img src={stockimg} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>


    )
};
export default FutureBanks;
