import React from 'react'
import { HashLink as HLink } from 'react-router-hash-link';

import './style.css'

const Gettouch = () => {
  return (
    <div className="get-touch-sec">
      <div className="container">
        <div className="get-text text-center">
          <h2>We are building the next generation of digital finance services, please reach out to work with us...</h2>
          <HLink smooth to="/contact#contactus" title="">Get in Touch</HLink>
        </div>
      </div>
    </div>
  )
}

export default Gettouch;
