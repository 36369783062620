import React from 'react'
import Headericon from '../../images/user_icon.png'
import { Link } from 'react-router-dom'
import MobileMenu from '../../components/MobileMenu'

import './style.css'

const Header = () => {
    return (
        <div className="middle-header">
            <div className="container">
                <div className="header-content">
                    <div className="logo">
                        <Link to="#"><h3 style={{ color: "#006a6a" }}>Pecutus</h3></Link>
                    </div>
                    <nav className="d-lg-block d-none">
                        <ul>
                            <li><Link to="/home" title="">Home</Link></li>
                            <li><Link to="/readytravel">Readytravel</Link></li>
                            <li><a href="https://fin.pecutus.com/en">Fin Business</a></li>
                            <li><Link to="/team">Team</Link></li>
                            <li><Link to="/careers" title="">Careers</Link></li>
                            <li><Link to="/faq" title="">FAQ</Link></li>
                            <li><Link to="/contact" title="">Contact</Link></li>
                        </ul>
                    </nav>
                    <div className="contact">
                        <img src={Headericon} alt="" />
                        <div className="contact-info">
                            <span>readytravel Service</span>
                            <a href="https://pecutus.atlassian.net/servicedesk/customer/portals"><h4 style={{ color: '#005f5d' }}>Contact Support</h4></a>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
                <MobileMenu />
            </div>
        </div>
    )
}

export default Header;
